import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import '../css/style.css';
import Header from './header';

function Layout({ children }) {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<div className="min-h-screen">
			<Header siteTitle={data.site.siteMetadata.title} />

			<main className="w-full">{children}</main>

			<footer className="absolute bottom-0 w-full text-white text-xs  text-center p-2 opacity-75 font-serif">&copy; 2019 Milwaukee Flower Wall</footer>
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
