import PropTypes from 'prop-types';
import React from 'react';

function Header({ siteTitle }) {
	return <nav></nav>;
}

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
