import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/layout';
import styled from 'styled-components';

export default function success() {
	return (
		<StaticQuery
			query={graphql`
				query {
					desktop: file(relativePath: { eq: "roses.jpg" }) {
						childImageSharp {
							fluid(quality: 90, maxWidth: 4160) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			`}
			render={data => {
				// Set ImageData.
				const imageData = data.desktop.childImageSharp.fluid;
				return (
					<StyledBackgroundSection>
						<Layout>
							<BackgroundImage
								style={{
									width: '100%',
									position: 'relative',
									backgroundPosition: 'bottom center',
									backgroundRepeat: 'repeat-y',
									backgroundSize: 'cover',
									minHeight: '100vh',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
								Tag="section"
								fluid={imageData}
								backgroundColor={`#040e18`}
							>
								<div className="absolute top-0 right-0 left-0 bottom-0 bg-red-dark opacity-50"></div>
								<div className="relative mb-24 px-4">
									<header className="relative z-2 w-full text-center text-white ">
										<div className="w-full text-2xl md:text-4xl lg:text-6xl font-light tracking-wider mb-4">
											MILWAUKEE <span className="block lg:inline-block">FLOWER WALL</span>
										</div>
										<p className="text-md md:text-lg font-semibold text-red-lighter tracking-widest mb-6 lg:mb-12">RENT A BEAUTIFUL FLOWER WALL FOR YOUR NEXT EVENT</p>
									</header>
									<section class="relative z-2 w-full md:w-3/4 lg:w-1/2 mx-auto">
										<p className="text-white text-md md:text-lg text-center">Thank you! Your message has been sent.</p>
									</section>
								</div>
							</BackgroundImage>
						</Layout>
					</StyledBackgroundSection>
				);
			}}
		/>
	);
}

const StyledBackgroundSection = styled.div``;
